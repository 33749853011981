const envSettings = window as any;
export class ConfigurationVariables {
  static KONG_URL = envSettings.ENV.KONG_URL;
  static FORGE_IAM_CLIENT_ID = envSettings.ENV.FORGE_IAM_CLIENT_ID;
  static FORGE_IAM_SCOPE = envSettings.ENV.FORGE_IAM_SCOPE;
  static FORGE_IAM_AUTHORITY = envSettings.ENV.FORGE_IAM_AUTHORITY;
  static TenantMgmt_Endpoints = envSettings.ENV.TenantMgmt_Endpoints;
  static UserMgmt_Endpoints = envSettings.ENV.UserMgmt_Endpoints;
  static Api = envSettings.ENV.Api;
  static Version = envSettings.ENV.Version;
  static TenantController = envSettings.ENV.TenantController;
  static UserController = envSettings.ENV.UserController;
  static RouteLogo = envSettings.ENV.RouteLogo;
  static RouteDatacenters = envSettings.ENV.RouteDatacenters;
  static RouteCurrencies = envSettings.ENV.RouteCurrencies;
  static RouteTimezones = envSettings.ENV.RouteTimezones;
  static RouteApplications = envSettings.ENV.RouteApplications;
  static RouteTenants = envSettings.ENV.RouteTenants;
  static RouteUserPermissions = envSettings.ENV.RouteUserPermissions;
  static DebounceTime_InMilliSeconds = envSettings.ENV.DebounceTime_InMilliSeconds;
  static RouteForgeCustomerTenantDetails = envSettings.ENV.RouteForgeCustomerTenantDetails;
  static GeoMappingAddress = envSettings.ENV.GeoMappingAddress;
}